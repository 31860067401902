<template>

  <Table title="Sources" :heads="heads" :rows="rows" :gradients="gradients" />

</template>

<script>
import Table from "../components/TableV2";
export default {
  components: {
    Table,
  },
  props: {
    doGet: Function,
  },
  data() {
    return {

      heads: [],
      rows : [],

      timer: undefined,

    }
  },
  mounted() {
    this.fetchData();
    this.timer = process.env.NODE_ENV == 'development'
        ? setInterval(this.fetchData, 5 * 1000)
        : setInterval(this.fetchData, 60 * 1000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  computed: {
    gradients() {
      let heads = this.heads.slice(1);
      return heads.reduce((gradients, head) => {
        gradients[head] = {
          ranges: heads,
          cssClassFn: window.utils.cssRedGreenGradient
        };
        return gradients;
      }, {});
    },
  },
  methods: {
    async fetchData () {

      let data = await this.doGet('/api/sources');

      this.heads = data[0];
      this.rows  = data.slice(1);

    },
  }
};
</script>
